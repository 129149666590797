* {
    box-sizing: border-box;
}

body {
    background: #1D3124;
    font-family: 'Delicious Handrawn', cursive;
    font-family: 'Golos Text', sans-serif;
    font-family: 'Inconsolata', monospace;
    margin: 0;
}
.container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    color: #F6F4E8;

}

.row .task-icon {
    margin-left: auto;
    margin-right: 32px;
}

.row .done-icon {
    margin-right: 10px;
}

.row .tasker-name {
    margin-left: 10px;
}

.refresh-button {
    display: none;
}


.top-headings {
    padding-bottom: 10px;
}

.arvonta {

    padding: 10px;
    background: #1D3124;
    color: #F6F4E8;
}

.arvonta h1 {
    padding-top: 20px;
    padding-bottom: 10px;

}

.login-form {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: auto;
    padding: 20px;
    margin-top: 30px;
    border: solid 2px #F6F4E8;
}

.login-form input {

    width: 50%;
    margin: 10px auto;
    border-radius: 0;

    outline: none;

}

.login-form p {
    font-size: 18px;
    margin: 0;
}

.login-form input:focus {


    outline-color: #1D3124;
}

.login-form button {
    border: solid 2px #F6F4E8;
    font-size: 15px;
    margin: auto;
    margin-top: 8px;
    padding: 5px 10px;
    background: #1D3124;
    color: #F6F4E8;


}

.logout-button {
    border: solid 2px #F6F4E8;
    font-size: 10px;
    margin: auto;
    margin-top: 8px;
    padding: 5px 10px;
    background: #1D3124;
    color: #F6F4E8;
    cursor: pointer;


}

.navbar {
    display:flex;
    align-items: center;
    padding-bottom: 100px;
    border-bottom: #F6F4E8 solid 1.5px;


}

.user-name {
    position: absolute;
    left: 20px;
    top: 20px;
}

.logout-btn-div {
    position: absolute;
    top: 20px;
    right: 20px;
}

.task-box {
    border: 2px solid #F6F4E8;
    background: #1D3124;
    color: #F6F4E8;
    margin: 20px auto;
    width: 90%;
    transition: 0.2s;
    cursor:pointer;
}

.task {
    text-transform: uppercase;
    color: #F6F4E8;
    display: inline-block;


}

.checkbox {
    margin-left: 15px;
}

.done-task {
    text-decoration: line-through;
    display: inline-block;

}

.done-div .done-task{
    font-size: 18px;
    margin-top: 4px;
}

.done-div .done-name {
    font-size: 22px;
    margin-bottom: 8px;
}

.check-span {
    margin-left: 5px;
    margin-right: 10px;
}

.done-out {
    display:flex;
    flex-direction: row;
    align-items: center;
}

.done-out p {

    margin-left: 5px;
    font-size: 16px;
}



.checkmark {
    display: inline-block;
    margin-left: 20px;
}




.task-box .task {
    font-size: 18px;
    margin-top: 4px;
}

.task-box .task-name {
    font-size: 22px;
    margin-bottom: 8px;

}

.done-task p {
    margin: 0;

}

.task-history-h4 {
    color: #F6F4E8;
    margin-bottom: 30px;
    margin-top:16px;
}

h1 {
    text-transform: uppercase;
    font-size: 30px;

}






.recent-tasks {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 10px;
    width: 90%;
    margin: auto;
    padding-bottom: 30px;

}
.recent-tasks>* {
    flex: 0 0 10%;

}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;


}

.first-item {
    align-self: flex-start;
}

.recent-tasks p{
    font-size: 10px;
    margin-bottom: 2px;
    margin-top: 2px;
}
.pet-div {
    background: #1D3124;
    color: #F6F4E8;
}

.expand {
    color: #F6F4E8;
    background: #1D3124;
    font-size: 35px;
    padding-bottom: 0;
}

.expand-logo {
    opacity: 40%;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;


}



.loader-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 50%;
}

.pet-div p {
    font-size: 18px;
    padding: 4px;



}

.food {
    padding: 30px 0;
    width: 90%;
    margin: auto;
    border: solid 2px #F6F4E8;
    margin-top: 30px;
    margin-bottom: 30px;


}

.outside {
    padding: 30px 0;
    width: 90%;
    border: solid 2px #F6F4E8;
    margin: auto;
    margin-bottom: 30px;



}

.done-pet-item {
    font-size: 30px;
}

.button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-left: 10px;
}

.button-div button {
    width: 50px;
    height: 50px;
    border-radius: 30px;
}


.pet-items{
    display: flex;
    align-items: center;
    justify-content: center;

}

.pet-items button {
    font-size: 10px;
    background: #F6F4E8;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 2px solid #E59560;
    margin-left: 10px;
    border-radius: 100px;

}

.history-box {
    border: solid 2px #F6F4E8;
    background: #1D3124;
    color: #F6F4E8;
    padding-bottom: 5px;
    padding-top: 5px;
}



.recent-tasks-container {
    width: 90%;
    margin: auto;
    text-align: center;
    padding-top: 10px;
    background: #1D3124;
    width: 100%;


}






@media only screen and (max-width: 800px) {
    .recent-tasks {
        flex-direction: row;
    }
    .recent-tasks>* {
        flex: 0 0 30%;

    }
    .row .task-icon {
        margin-left: auto;
        margin-right: 15px;
    }

    .row .done-icon {
        margin-right: 10px;
    }

    .row .tasker-name {
        margin-left: 10px;
    }

    .login-form {
        width: 80%;
    }

    .login-form input {
        height: 30px;
        caret-color: #1D3124;
    }
    .loggen-in-as {
        font-size: 10px;
    }

}